import EventBus from "./events/EventBus";
export function pluralize(count, one, two, five = two, placeholder = '%%') {
    let number = count % 100;
    let declension = one;
    if (number > 4 && number <= 20) {
        declension = five;
    }
    else {
        number = number % 10;
        if (number >= 5 || number === 0) {
            declension = five;
        }
        else if (number >= 2 && number <= 4) {
            declension = two;
        }
    }
    if (declension.match(placeholder)) {
        return declension.replace(placeholder, count.toString());
    }
    return declension;
}
export function format(number) {
    if (!number) {
        return '0';
    }
    number = number.toString();
    if (number.match(/\d{4}/)) {
        return format(number.replace(/(\d)(\d{3})\b/, '$1 $2'));
    }
    return number;
}
export function addGlobalLiveEventListener(events, elementSelector, callback) {
    addLiveEventListener(document.documentElement, events, elementSelector, callback);
}
export function addLiveEventListener(element, events, elementSelector, callback) {
    elementSelector = typeof elementSelector === 'string' ? [elementSelector] : elementSelector;
    for (const eventType of events.split(' ')) {
        element.addEventListener(eventType, function (event) {
            if (!(event.target instanceof HTMLElement || event.target instanceof SVGElement)) {
                return;
            }
            for (const selector of elementSelector) {
                const el = event.target.closest(selector);
                if (el) {
                    Object.defineProperty(event, 'target', { value: el });
                    return callback.call(el, event);
                }
            }
        });
    }
}
/**
 * This allows us to dispatch browser events in old IE and newer browsers
 */
export function triggerEvent(elem, eventName, data = null) {
    const event = data ? new CustomEvent(eventName, { detail: data }) : new CustomEvent(eventName);
    elem.dispatchEvent(event);
    return event;
}
export function join(arr, separator = ' ') {
    return arr.filter(n => n).join(separator);
}
export function escapeRegex(string) {
    return string.replace(/[\/\\^$*+?.()|[\]{}]/g, '\\$&');
}
export const isMobile = window.matchMedia('(pointer: coarse)').matches && window.innerWidth <= 768 && 'ontouchstart' in window;
export function getFetchOptions(body, credentials = false, method = 'POST') {
    return Object.assign({ method: method, headers: {
            'Content-Type': 'application/json',
        }, credentials: credentials ? 'same-origin' : 'omit' }, (body ? { body: JSON.stringify(body) } : {}));
}
export function fixInjectedInlineScripts(element) {
    for (const script of element.querySelectorAll('script')) {
        const newScript = document.createElement('script');
        newScript.text = script.innerHTML;
        script.parentElement.append(newScript);
        script.remove();
    }
}
export function isHidden(el) {
    return (el.offsetParent === null);
}
export function onModalOpen(modalId, callback) {
    EventBus.on('modal:open', function (modal) {
        if (!modal || modal.id !== modalId) {
            return;
        }
        callback(modal);
    });
}
export function onTransitionEnd(el, callback, property = 'transform') {
    function listener(e) {
        if (e.target !== el || (property && e.propertyName !== property)) {
            return;
        }
        el.removeEventListener('transitionend', listener);
        callback();
    }
    el.addEventListener('transitionend', listener);
}
export function emulateReadonly(element) {
    element.style.caretColor = 'transparent';
    element.addEventListener('keydown', onkeypress);
    element.addEventListener('paste', onkeypress);
    function onkeypress(event) {
        if (event.key.length === 1 || ['Backspace', 'Delete'].includes(event.key)) {
            event.preventDefault();
        }
    }
}
export function clearDataset(el) {
    Object.keys(el.dataset).forEach(key => delete el.dataset[key]);
}
export function extractAppData(el) {
    return extractData(el.querySelector('script[type="application/json"]'));
}
export function extractData(el) {
    return el ? JSON.parse(el.textContent) : null;
}
export function startIconLoading(el) {
    el.querySelector('.icon').classList.add('icon--loading');
}
export function stopIconLoading(el) {
    el.querySelector('.icon').classList.remove('icon--loading');
}
export function isFullyVisible(element, clippingElement = null, checkViewport = true) {
    const rect = element.getBoundingClientRect();
    if (!clippingElement || checkViewport) {
        // Check viewport visibility as before
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const isViewportVisible = (rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowHeight && rect.right <= windowWidth);
        // If no clipping element provided, return viewport visibility
        if (!isViewportVisible || !clippingElement) {
            return isViewportVisible;
        }
    }
    // Get clipping element's rect
    const clippingRect = clippingElement.getBoundingClientRect();
    return rect.top >= clippingRect.top && rect.left >= clippingRect.left
        && rect.bottom <= clippingRect.bottom && rect.right <= clippingRect.right;
}
export function onScrollEnd(callable, once = true) {
    document.addEventListener('scroll', () => {
        let lastScrollPosition = window.scrollY;
        const interval = setInterval(() => {
            if (lastScrollPosition !== window.scrollY) {
                lastScrollPosition = window.scrollY;
                return;
            }
            clearInterval(interval);
            callable();
            if (!once) {
                onScrollEnd(callable, once);
            }
        }, 20);
    }, { passive: true, once: true });
}
export function toggleSiblingsClasses(element, className, siblingSelector = ':scope > *', parent = null) {
    parent = typeof parent === 'string' ? element.closest(parent) : parent !== null && parent !== void 0 ? parent : element.parentElement;
    parent.querySelectorAll(siblingSelector).forEach(el => el.classList.remove(className));
    element.classList.add(className);
}
let webpSupported = false;
export function isWebpSupported() {
    return webpSupported;
}
function testWebPSupport() {
    const webP = new Image();
    webP.src = 'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==';
    webP.onload = webP.onerror = () => webpSupported = webP.height === 1;
}
testWebPSupport();
